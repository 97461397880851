<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(startup)="data">
            <b-link
              :to="$route.path + '/view/' + data.item.id_startups"
              class="text-body"
            >
              {{ data.item.startup }}
              <div class="text-muted font-small-2">
                {{ data.item.member }}
              </div>
              <div class="text-danger font-small-2 font-weight-bold">
                {{ data.item.dealer }}
              </div>
            </b-link>
          </template>
          <template #cell(status)="data">
            <div class="text-center">
              <FeatherIcon
                v-if="data.item.status === '1'"
                class="text-success"
                icon="CheckIcon"
              />
              <FeatherIcon
                v-else
                class="text-danger"
                icon="XIcon"
              />
            </div>
          </template>
          <template #cell(startup_statuses)="data">
            <div>{{ data.item.startup_statuses }}</div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="$route.path + '/view/' + data.item.id_startups"
            >
              <FeatherIcon icon="EyeIcon" />
              Görüntüle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BLink,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BLink,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'startup',
          label: 'Girişim',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'startup_status',
          label: 'Durum',
          thClass: 'width-200 text-nowrap',
          tdClass: 'text-nowrap',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thClass: 'width-50 text-nowrap',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['startupApplies/getStartupApplies']
    },
    dataCount() {
      return this.$store.getters['startupApplies/getStartupAppliesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('startupApplies/startupAppliesList', this.dataQuery)
    },
  },
}
</script>
